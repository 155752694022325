import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

// Background
import bgEmprestimoConsignadoMD from '../../assets/images/bg-emprestimo-consignado-md.png'
import bgEmprestimoConsignadoLG from '../../assets/images/bg-emprestimo-consignado-lg.png'
import bgEmprestimoConsignadoXL from '../../assets/images/bg-emprestimo-consignado-xl.png'

export const Section = styled.section`

  a {
    @media ${device.desktopLG} {
      max-width: 460px;
    }

    @media ${device.desktopXL} {
      max-width: 545px;
    }
  }

  @media ${device.tablet} {
    display: flex;
    align-items: flex-end;
    background-image: url(${bgEmprestimoConsignadoMD});
    background-repeat: no-repeat;
    background-size: contain;
    height: 520px;
    background-position: bottom right; 
  }

  @media ${device.desktopLG} {
    height: 530px;
    background-image: url(${bgEmprestimoConsignadoLG});
  }

  @media ${device.desktopXL} {
    height: 530px;
    background-image: url(${bgEmprestimoConsignadoXL});
  }
`