import React from 'react'
import Img from 'gatsby-image'
import LinkTo from 'src/components/LinkTo/LinkTo'

import usePageQuery from '../../pageQuery'

import { Section, DarkCTA } from './style'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'

const FecheUmContratoDeConsignado = ({ sendDatalayerEvent }: DataLayerProps) => {
  const imagesData = usePageQuery()

  const fluidImages = [
    {
      ...imagesData.bannerFecheUmContratoDeConsignadoSM.fluid,
    },
    {
      ...imagesData.bannerFecheUmContratoDeConsignadoXL.fluid,
      media: '(min-width: 1200px)',
    },
    {
      ...imagesData.bannerFecheUmContratoDeConsignadoLG.fluid,
      media: '(min-width: 992px)',
    },
    {
      ...imagesData.bannerFecheUmContratoDeConsignadoMD.fluid,
      media: '(min-width: 768px)',
    },
  ]

  return (
    <Section className='py-5' id='portabilidade'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5 offset-xl-2 order-md-2'>
            <Img fluid={fluidImages} className='image' alt='Ilustração cartão Black.' />
          </div>
          <div className='col-12 col-md-6 col-xl-5 order-md-1'>
            <h2 className='fs-24 fs-md-32 lh-30 lh-md-40 text-white fw-600 mt-4 mt-md-0'>
              Feche um contrato de Consignado acima de R$ 100 mil e aproveite seu Cartão Inter Black recheado de benefícios!
            </h2>
            <p className='fs-16 fs-md-18 lh-20 text-white mt-3'>
              No Inter, seu Cartão Black também te dá acesso a seguros que garantem tranquilidade, onde quer que você esteja.
            </p>
            <ul className='fs-16 fs-md-18 text-white pl-md-4 mb-5'>
              <li>Sala VIP em Aeroportos</li>
              <li>Programa Mastercard Surpreenda</li>
              <li>Viagens com conforto</li>
              <li>Seguro médico em viagens</li>
              <li>Proteção de bagagem</li>
              <li>Priceless Cities</li>
            </ul>
            <DarkCTA>
              <LinkTo
                to='#hero-section'
                section='dobra_11'
                sectionName='Feche um contrato de Consignado acima de R$ 100 mil e aproveite seu Cartão Inter Black recheado de benefícios!'
                elementName='Quero meu Cartão Black!'
                sendDatalayerEvent={sendDatalayerEvent}
              > Quero meu Cartão Black!
              </LinkTo>
            </DarkCTA>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default FecheUmContratoDeConsignado
