import React from 'react'
import Link from 'components/GatsbyLinkWrapper/index'
import useWidth from 'src/hooks/window/useWidth'

import bgEmprestimoConsignadoMobile from '../../assets/images/bg-emprestimo-consignado-mobile.png'

import { DataLayerProps } from 'src/hooks/useDataLayer/types'
import { Section } from './style'

const WIDTH_MD = 768

const EmprestimoConsignadoINSS = ({ sendDatalayerEvent }: DataLayerProps) => {
  const windowWidth = useWidth(300)

  return (
    <Section className='pt-3 pt-md-5 pb-md-3 pb-lg-5 pt-lg-5 bg-gray'>
      <div className='container'>
        <div className='row'>
          {
          windowWidth < WIDTH_MD &&
            <div className='col-12 col-md-6 order-md-last d-flex justify-content-center'>
              <img src={bgEmprestimoConsignadoMobile} />
            </div>
          }
          <div className='col-12 col-md-6 pr-0'>
            <h2 className='fs-24 fs-md-32 fs-lg-40 lh-30 lh-md-40 lh-lg-50 text-grayscale--500'>
              Empréstimo Consignado INSS: simplicidade e segurança para você!
            </h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 text-grayscale--500 mt-md-3'>
              Dinheiro rápido na sua conta, para usar como quiser e com a garantia de segurança Inter.
              Contrate seu Crédito Consignado com até 84 meses para pagar e parcelas a partir de R$ 42,60!
            </p>
            <Link
              className='btn btn-orange--extra fs-12 lh-17 fw-700 rounded-2 text-none mt-2'
              to='/pra-voce/emprestimos/emprestimo-consignado-inss/'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_4',
                  section_name: 'Empréstimo Consignado INSS: simplicidade e segurança para você!',
                  element_name: 'Simule seu Consignado!',
                  element_action: 'click button',
                  redirect_url: window.location.href + '/pra-voce/emprestimos/emprestimo-consignado-inss/',
                })
              }}
            > Simule seu Consignado!
            </Link>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default EmprestimoConsignadoINSS
