import React from 'react'
import Img from 'gatsby-image'
import LinkTo from 'src/components/LinkTo/LinkTo'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'

import usePageQuery from '../../pageQuery'

import { Section, Title } from './style'

const ComoContratarConsignado = ({ sendDatalayerEvent }: DataLayerProps) => {
  const imagesData = usePageQuery()

  return (
    <Section className='py-5 bg-grayscale--100' id='pedirConsignado'>
      <div className='container py-md-5'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-5 col-lg-5 mb-4 mb-md-0'>
            <Img fluid={imagesData.bannerComoContratarOConsignadoInter.fluid} alt='homem de jaqueta azul com seu smartphone na mão.' />
          </div>
          <div className='col-12 col-md-6 col-xl-6 p-0'>
            <Title>
              Como contratar o <br /> Consignado Inter
            </Title>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 fw-400 text-grayscale--500'>
              Para contratar o Consignado Inter, você só precisa fazer a simulação via nosso site ou app e depois prosseguir com a contratação – que pode ser realizada de forma 100% digital ou com algum de nossos consultores.
            </p>
            <LinkTo
              to='#hero-section'
              btncolor='button mt-4 w-100'
              section='dobra_9'
              sectionName=' Como contratar o Consignado Inter'
              elementName='Simule seu Consignado!'
              sendDatalayerEvent={sendDatalayerEvent}
            > Simule seu Consignado!
            </LinkTo>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ComoContratarConsignado
