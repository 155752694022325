import React from 'react'
import Img from 'gatsby-image'
import LinkTo from 'src/components/LinkTo/LinkTo'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'

import usePageQuery from '../../pageQuery'

import { Section, Title } from './style'

const CartaoBeneficioINSS = ({ sendDatalayerEvent }: DataLayerProps) => {
  const imagesData = usePageQuery()

  return (
    <Section className='py-5 bg-grayscale--100' id='credito-protegido'>
      <div className='container py-md-5'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-1 mb-4 mb-md-0 order-md-last'>
            <Img fluid={imagesData.bannerCartaoBeneficioINSS.fluid} alt='Mulher com camisa laranja sorrindo.' />
          </div>
          <div className='col-12 col-md-6 col-lg-6 p-0'>
            <Title>
              Cartão Benefício <span className='d-md-block d-xl-inline'>INSS. Aproveite!</span>
            </Title>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 fw-400 text-grayscale--500'>
              Além do Consignado, aposentados e pensionistas também podem aproveitar o Cartão Consignado Benefício INSS,
              um cartão de crédito com muito mais vantagens para você!
            </p>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 fw-400 text-grayscale--500'>
              Faça compras no crédito ou saque até 70% do seu limite, pague em até 84 parcelas fixas mensais, e aproveite o
              restante como quiser.
            </p>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 fw-400 text-grayscale--500'>
              Até 5% do valor utilizado é descontado diretamente no seu benefício, mas o melhor: sem comprometer em nada a
              sua margem de empréstimo.
            </p>
            <LinkTo
              to='#hero-section'
              btncolor='button mt-4 w-100'
              section='dobra_14'
              sectionName='Cartão Benefício INSS. Aproveite!'
              elementName='Garanta seu Cartão Benefício!'
              sendDatalayerEvent={sendDatalayerEvent}
            > Garanta seu Cartão Benefício!
            </LinkTo>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default CartaoBeneficioINSS
