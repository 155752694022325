import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

import ImageBackgroundSM from '../../assets/images/background-feche-um-contrato-de-consignado-sm.png'
import ImageBackgroundMD from '../../assets/images/background-feche-um-contrato-de-consignado-md.png'
import ImageBackgroundLG from '../../assets/images/background-feche-um-contrato-de-consignado-lg.png'
import ImageBackgroundXL from '../../assets/images/background-feche-um-contrato-de-consignado-xl.png'

import { grayscale, orange } from 'src/styles/colors'

export const Section = styled.section`
  background-color: ${grayscale[500]};
  display: flex;
  align-items: center;
  background-image: url(${ImageBackgroundSM});
  background-repeat:  no-repeat;
  background-position: -9% 10%;

  @media ${device.tablet} {
    background-image: url(${ImageBackgroundMD});
    background-repeat:  no-repeat;
    background-position: 285% center;
  }
  @media ${device.desktopLG} {
    background-image: url(${ImageBackgroundLG});
    background-repeat:  no-repeat;
    background-position: 95% center;
  }
  @media ${device.desktopXL} {
    background-image: url(${ImageBackgroundXL});
    background-repeat:  no-repeat;
    background-position: 100% center;
  }
`

export const DarkCTA = styled.div`
  a {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 17px;
    color: ${orange.extra};
    font-weight: 700;
    height: 48px;
    border-radius: 8px;
    padding: 16px;
    width: auto;
    border: 1px solid #FFB46E;

    &:hover {
      opacity: 0.8;
    }
  }
`
